<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-12 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          売上管理（日別詳細）
        </h2>
        <div
          class="px-0 col-sm-12 col-md-6 col-lg-6 tex-left text-md-right text-sm-left"
        >
          <router-link
            :to="{
              name: $route.params.shopId
                ? 'listSaleManagementRanks'
                : 'listSaleManagementRanks domain',
            }"
          >
            <button class="mr-2 button-create-user" @click="ranking()">
              ランキング
            </button>
          </router-link>
          <button class="button-create-user" @click="downloadCSV()">
            CSV出力
          </button>
          <button
            v-on:click="gotoDashboard()"
            class="button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="row p-3 d-flex">
        <label class="col-12 font-weight-bold pl-3">対象日を指定</label>
        <div class="row-input p-3">
          <date-picker
            v-model="dateSelected"
            type="date"
            locale="ja"
            valueType="YYYY-MM-DD"
          ></date-picker>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="dateSelectedDateFrom()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <div class="d-flex position-relative">
        <div>
          <p class="font-weight-bold">{{ year }}年{{ month }}月{{ date }}日</p>
        </div>
        <div class="pl-5">
          <p class="font-weight-bold">総計：{{ total_revenue }}円</p>
        </div>
      </div>
      <Tables
        :items="listDetailSaleManagementDailyData.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:status_order="{ item }">
          <td v-if="item.status_order == 0">未承認</td>
          <td v-else-if="item.status_order == 1">承認済み</td>
          <td v-else-if="item.status_order == 2">キャンセル</td>
        </template>
        <template v-slot:title="{ item }">
          <td>
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId
                  ? 'edit content'
                  : 'edit content domain',
                params: { shopId: shop_id, id: item.content_id },
              }"
            >
              {{ item.title }}
            </router-link>
          </td>
        </template>
        <template v-slot:name="{ item }">
          <td>
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId ? 'edit user' : 'edit user domain',
                params: {
                  shopId: shop_id,
                  id: item.user_id,
                },
              }"
            >
              {{ item.name }}
            </router-link>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div>
              <!-- <router-link
                :to="{
                  name: $route.params.shopId ? 'EditOrder' : 'EditOrder domain',
                  params: { shopId: $route.params.shopId, id: item.id },
                }"
                class="d-flex flex-column align-items-center"
              > -->
              <CButton
                class="mx-1 btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-comfirm
                v-on:click="detailOrder(item.id)"
              >
                詳細
              </CButton>
              <!-- </router-link> -->
            </div>
          </td>
        </template>
      </Tables>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button v-on:click="backMonth()" variant="secondary" class=""
          >＜前日へ</b-button
        >
        <b-button variant="secondary" class="ml-4" v-on:click="nextMonth()"
          >翌日へ＞</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import Encoding from "encoding-japanese";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ja";
import moment from "moment";
export default {
  name: "listDetailSaleManagementDailys",
  data() {
    return {
      fields: tableFields.DETAIL_SALE_MANAGEMENT_DAILY,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      daily: "",
      page: 1,
      year: 0,
      month: 0,
      date: 0,
      messageError: {},
      messageResponse: "",
      messagerErr: "",
      messageSuccess: "",
      total_revenue: 0,
      lang: "ja",
      dateSelected: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listDetailSaleManagementDailyData: [],
    };
  },
  components: {
    Tables,
    DatePicker,
  },
  created() {
    const date = this.$route.params.daily.split("-");
    this.year = date[0];
    this.month = date[1];
    this.date = date[2];
    this.getlistDetailSaleManagementDailys();
  },
  computed: {
    ...mapGetters([
      "listDetailSaleManagementDaily",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listDetailSaleManagementDaily() {
      let total = 0;
      this.json_data = this.listDetailSaleManagementDaily.data;
      this.json_data.forEach((element) => {
        total += parseInt(element.total_amount);
      });
      this.total_revenue = Number(total).toLocaleString("ja");
      this.listDetailSaleManagementDailyData =
        this.listDetailSaleManagementDaily;
    },
  },
  methods: {
    ...mapActions({
      getDetailSaleManagetmentDaily: "getDetailSaleManagetmentDaily",
    }),
    changeLang() {
      this.lang === "ja";
    },
    getlistDetailSaleManagementDailys() {
      const formData = {
        shop_id: this.shop_id,
        date: this.$route.params.daily,
        back: "",
        next: "",
      };
      this.getDetailSaleManagetmentDaily(formData);
    },
    changeLimitPage() {
      this.getlistSaleManagementDailys();
    },
    async csvExport(datas, dataRequest) {
      const url = `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.DAILY_DETAIL}/${Urls.EXPORT}`;
      const method = "POST";
      let response = await Api.adminRequestServer({
        method,
        url,
        data: dataRequest,
      });
      var { data } = response.data;
      if (data.success === false) return datas;
      datas += data.data;
      this.index++;
      if (this.index % Constants.LIMIT_CSV == 0) {
        const getDate = new Date();
        const nameExcel =
          "売上管理（日別）" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        let file = "対象日,購入コンテンツ,売上（円）,購入ユーザー名）\n";
        file += datas;
        datas = [];
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      return datas;
    },
    async downloadCSV() {
      this.messageError = {};
      this.messageResponse = "";
      this.messagerErr = "";

      try {
        this.index = 0;
        var datas = [];
        let dataRequest = {
          shop_id: this.shop_id,
          date: this.dateSelected
            ? this.dateSelected
            : this.$route.params.daily,
        };
        datas = await this.csvExport(datas, dataRequest);
        if (Object.keys(datas).length == 0) {
          if (this.index == 0) {
            this.messageResponse = "データがありません";
          } else {
            this.messageSuccess = "ダウンロードが完了しました";
          }
          // $("body").removeClass("wait");
          return;
        }
        const getDate = new Date();
        const nameExcel =
          "売上管理（日別）" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        Math.floor(this.index / Constants.LIMIT_CSV) + 1 + ".csv";
        let file = "対象日,購入コンテンツ,売上（円）,購入ユーザー名）\n";
        file += datas;
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageSuccess = "ダウンロードが完了しました";
        // $("body").removeClass("wait");
      } catch (e) {
        // $("body").removeClass("wait");
        this.messageResponse = "システムエラーが発生しました";
        console.log(e);
      }
    },
    changeStatus(value) {
      this.status_order = value;
    },
    resetFieldSearch() {
      this.dateSelected = "";
      let dateSelect = this.$route.params.daily.split("-");
      this.year = dateSelect[0];
      this.month = dateSelect[1];
      this.date = dateSelect[2];
      const formData = {
        shop_id: this.shop_id,
        date: this.$route.params.daily,
        back: "",
        next: "",
      };
      this.getDetailSaleManagetmentDaily(formData);
    },
    gotoDashboard() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Menu SaleManagerment"
          : "Menu SaleManagerment domain",
      });
    },
    dateSelectedDateFrom() {
      let dateSelect = this.dateSelected.split("-");
      this.year = dateSelect[0];
      this.month = dateSelect[1];
      this.date = dateSelect[2];
      const formData = {
        shop_id: this.shop_id,
        date: this.dateSelected,
        back: "",
        next: "",
      };
      this.getDetailSaleManagetmentDaily(formData);
    },
    nextMonth() {
      let getDateFomat = null;
      if (this.listDetailSaleManagementDaily.data.length > 0) {
        getDateFomat = this.listDetailSaleManagementDaily.data[0].date;
      } else if (this.$route.params.daily === null) {
        getDateFomat = new Date();
      } else {
        getDateFomat = this.$route.params.daily;
      }
      const Datefomat = moment(getDateFomat).add(1, "d");
      const paramsDate = Datefomat.format("YYYY-MM-DD");
      const formData = {
        shop_id: this.shop_id,
        date: this.$route.params.daily,
        next: "next",
        back: "",
      };
      this.getDetailSaleManagetmentDaily(formData);
      this.$router.push({
        name: this.$route.params.shopId
          ? "detailSaleManagementDaily"
          : "detailSaleManagementDaily domain",
        params: { daily: paramsDate },
      });
    },
    backMonth() {
      let getDateFomat = null;
      if (this.listDetailSaleManagementDaily.data.length > 0) {
        getDateFomat = this.listDetailSaleManagementDaily.data[0].date;
      } else if (this.$route.params.daily === null) {
        getDateFomat = new Date();
      } else {
        getDateFomat = this.$route.params.daily;
      }
      const Datefomat = moment(getDateFomat).add(-1, "d");
      const paramsDate = Datefomat.format("YYYY-MM-DD");
      const formData = {
        shop_id: this.shop_id,
        date: this.$route.params.daily,
        next: "",
        back: "back",
      };
      this.getDetailSaleManagetmentDaily(formData);
      this.$router.push({
        name: this.$route.params.shopId
          ? "detailSaleManagementDaily"
          : "detailSaleManagementDaily domain",
        params: { daily: paramsDate },
      });
    },
    detailOrder(id) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "EditOrder",
          params: { shopId: this.$route.params.shopId, id: id },
        });
      } else {
        this.$router.push({
          name: "EditOrder domain",
          params: { id: id },
        });
      }
    },
  },
};
</script>
